/* @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");

* {
  /* mini reset */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: inherit;
  text-align: center;
}

:root {
  /* font for entire document */
  --base-font: "Poppins", sans-serif;

  /* desktop type styles */
  --heading: 700 40px var(--base-font);
  --text: 400 40px var(--base-font);
  --info: 400 30px var(--base-font);

  /* this is the same as desktop text above for now but might need to be reduced */
  --results-text: 400 30px var(--base-font);
  --results-icon: 400 60px var(--base-font);

  /* color styles */

  /* --app-background-color: #e0e0e0;
  --text-main-color: #000;
  --text-softened-color: #828282;
  --button-neutral-color: #c0c0c0;
  --button-hover-color: #b0b0b0;
  --button-click-color: #d0d0d0; */
  --app-background-color: #33d7cf;
  --text-main-color: #0a3331;
  --text-softened-color: #167974;
  --card-background-color: #bffffb;
  --button-neutral-color: #99fff8;
  --button-hover-color: #d6fffc;
  --button-click-color: #70fff5;

  /* variables for kinetic loader effect */
  --rotation-seconds: 5s;
  --loader-size: 35px;
  --loader-color: var(--card-background-color);

  /* border radius top left corner extra curved */
  --stylised-border-radius: 50px 5px 5px;
}

body {
  /* this will allow root to fully fill the page
  https://dev.to/lennythedev/css-gotcha-how-to-fill-page-with-a-div-270j */
  height: 100vh;
  width: 100vw;

  /* styling */

  /* fonts will be styled per-class via individual style variables found on :root above, this is as a failsafe across the document */
  font-family: var(--base-font);
}

#root {
  /* react #root div will fill the entire page and can be styled by subsequent css as if it were body
  (see article in body above) */
  min-height: 100%;
  width: 100%;

  /* this fixes a bug where the page doesnt paint fully in some small dimension but may cause a different bug where flex containers dont overflow correctly in other small dimensions - try excising it early in fixing css bugs */
  overflow: auto;

  /* flexbox */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  /* styling */
  background-color: var(--app-background-color);
}

/* utility classes */

.btn {
  border: none;
  font: var(--text);
  color: var(--text-main-color);
  background-color: var(--button-neutral-color);
  height: 88px;
  min-width: 200px;
  border-radius: var(--stylised-border-radius);
  filter: drop-shadow(0 4px 4px rgba(0 0 0 / 25%));
}

.btn:hover {
  background-color: var(--button-hover-color);
  filter: drop-shadow(0 8px 8px rgba(0 0 0 / 45%));
}

.btn:active {
  background-color: var(--button-click-color);
  filter: drop-shadow(0 4px 4px rgba(0 0 0 / 25%));
}

/* splash page - desktop */

.splashpage__heading {
  color: var(--text-main-color);
  font: var(--heading);
  margin-top: 160px;
}

.splashpage__instruction {
  color: var(--text-main-color);
  font: var(--text);
  margin-top: 208px;
}

.splashpage__startbutton {
  margin-top: 88px;
}

/* loading page - desktop */

/* css loading animation */
.loadingpage__kineticloader {
  margin-top: 32vh;
  position: relative;
  height: 80px;
  width: 80px;
}

.loadingpage__kineticloader::before,
.loadingpage__kineticloader::after {
  border-radius: calc(var(--loader-size) / 3);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  border: var(--loader-size) solid transparent;
  border-bottom-color: var(--loader-color);
}

.loadingpage__kineticloader::before {
  transform: rotate(90deg);
  animation: rotate-before var(--rotation-seconds) linear infinite;
}

.loadingpage__kineticloader::after {
  animation: rotate-after var(--rotation-seconds) linear infinite
    calc(var(--rotation-seconds) / 4);
}

@keyframes rotate-before {
  0%,
  25% {
    transform: rotate(90deg);
  }

  50%,
  25% {
    transform: rotate(270deg);
  }

  75%,
  100% {
    transform: rotate(450deg);
  }
}
@keyframes rotate-after {
  0%,
  25% {
    transform: rotate(0deg);
  }

  50%,
  25% {
    transform: rotate(180deg);
  }

  75%,
  100% {
    transform: rotate(360deg);
  }
}

.loadingpage__text {
  margin-top: 5%;
  font: var(--info);
}

/* gamepage - desktop */

.gamepage__heading {
  margin-top: 88px;
  color: var(--text-main-color);
  font: var(--heading);
}

.gamepage__card {
  background-color: var(--card-background-color);
  margin-top: 100px;
  min-height: 368px;
  width: 640px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 44px;
  filter: drop-shadow(0 4px 4px rgba(0 0 0 / 25%));
  border-radius: var(--stylised-border-radius);
}

.gamepage__cardtext {
  min-height: 0;
  color: var(--text-main-color);
  font: var(--text);

  /* the following two properties will force long words to break rather than overflow the card
  there is at least one long enough word in db */
  max-width: 95%;
  overflow-wrap: break-word;
}

.gamepage__buttonscontainer {
  margin-top: 80px;
  display: flex;
  flex-direction: row;
  gap: 240px;
}

.gamepage__button {
  transition: all 0.1s ease-in-out;
}

/* this will be disabled by js, indicates user choice when answer is revealed */
.btn.gamepage__button--chosen {
  /* this is needed to force text color on disabled button */
  background-color: var(--app-background-color);
  transform: scale(1.3);
  filter: none;
}

/* disabled button should not have hover state */
.btn.gamepage__button--chosen:hover {
  background-color: var(--app-background-color);
}

/* this will also be disabled - it indicates the non-chosen answer when answer is revealed */
.btn.gamepage__button--notchosen {
  color: var(--text-softened-color);
  background-color: var(--app-background-color);
  transform: scale(0.8);
  filter: none;
}

/* disabled button should not have hover state */
.gamepage__button--notchosen:hover {
  background-color: var(--app-background-color);
}

.gamepage__nextbutton {
  margin-top: 12px;
}

.gamepage__cardnumber {
  margin-top: 32px;
  font: var(--info);
}

/* results page */

.resultspage__heading {
  margin-top: 80px;
  color: var(--text-main-color);
  font: var(--heading);
}

.resultspage__resultslist {
  list-style-type: none;
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 24px;
}

.resultspage__resultsitem {
  background-color: var(--card-background-color);
  color: var(--text-softened-color);
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 42px;
  margin: 0 11vw;
  padding: 4vw;
  border-radius: var(--stylised-border-radius);
  width: 78vw;
}

.resultspage__resultsitem--correct {
  color: var(--text-main-color);
  opacity: 1;
  filter: drop-shadow(0 4px 4px rgba(0 0 0 / 25%));
}

.resultspage__texticon {
  font: var(--results-icon);
}

.resultspage__cardtext {
  font: var(--results-text);
  text-align: left;

  /* this  doesnt break a long word on its own in this element */
  max-width: 100%;
  overflow-wrap: break-word;
  flex: 1;
}

/* figure out why height doesn't inherit here */
.resultspage__replaybutton {
  margin: 40px 0 60px;
  min-height: 88px;
}

@media (max-width: 500px) {
  :root {
    /* mobile type styles */
    --heading: 700 24px var(--base-font);
    --text: 400 20px var(--base-font);
    --info: 400 18px var(--base-font);

    /* this is the same as mobile text above for now but might need to be reduced */
    --results-text: 400 16px var(--base-font);
    --results-icon: 400 36px var(--base-font);

    /* reduction in size of animated loading icon */
    --loader-size: 24px;

    /* smaller border-radius top left for mobile */

    --stylised-border-radius: 25px 5px 5px;

    /* adjust heading margin on mobile */
    --mobile-heading-margin-top: 44px;
  }

  /* utility classes */

  .btn {
    height: 46px;
    min-width: 92px;
    padding: 0 12px;
  }

  /* splashpage */

  .splashpage__heading {
    margin-top: var(--mobile-heading-margin-top);
    line-height: 28px;
  }

  .splashpage__instruction {
    margin-top: 176px;
  }

  .splashpage__startbutton {
    margin-top: 132px;
  }

  /* loading page */

  .loadingpage__kineticloader {
    margin-top: 45%;
  }

  /* game page */

  .gamepage__heading {
    margin-top: var(--mobile-heading-margin-top);
  }

  .gamepage__card {
    margin-top: 44px;
    min-height: 284px;
    width: 302px;
    padding: 26px 44px;
  }

  .gamepage__buttonscontainer {
    margin-top: 56px;
    gap: 56px;
  }

  .gamepage__button--chosen {
    /* scale to show ui looks bad on mobile */
    transform: scale(1);
  }

  .gamepage__button--notchosen {
    /* scale to show ui looks bad on mobile */
    transform: scale(1);
  }

  .gamepage__nextbutton {
    margin-top: 36px;
  }

  .gamepage__cardnumber {
    margin-top: 48px;
  }

  /* results page */

  .resultspage__heading {
    margin-top: var(--mobile-heading-margin-top);
    line-height: 28px;
  }

  .resultspage__resultslist {
    margin-top: 44px;
  }

  .resultspage__resultsitem {
    gap: 22px;
  }

  .resultspage__replaybutton {
    min-height: 46px;
  }
}
